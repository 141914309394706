@font-face {
  font-family: 'HKGrotesk';
  font-weight: normal;
  src: url('/static/app/fonts/HKGrotesk-Regular.woff');
}

@font-face {
  font-family: 'HKGrotesk';
  font-weight: bold;
  src: url('/static/app/fonts/HKGrotesk-Bold.woff');
}

:root {
  --font-family: 'HKGrotesk';
}

html {
  -webkit-tap-highlight-color: transparent;

  --accent-hue: 140;
  --accent-color: hsl(var(--accent-hue), 60%, 51%);       /* link; button-primary bg+border; textarea,select:focus border */
  --accent-color-hover: hsl(var(--accent-hue), 30%, 57%); /* link hover; button-primary:hover:focus bg+border */
  --background-tint: #f7f7f7;
  --primary-color: var(--accent-color);
}

.container {
  margin: 0 auto;
}

@media screen and (max-width: 576px) {
  .container {
    max-width: 100%;
  }
}
@media screen and (max-width: 768px) {
  .container {
    max-width: 80%;
  }
}
@media screen and (max-width: 992px) {
  .container {
    max-width: 992px;
  }
}
@media screen and (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}

body {
  text-align: center;
  font-family: 'HKGrotesk';

  min-height: 96vh;
}

hr {
  border-top: 1px solid lightgray;
}

/*.container {
  min-height: 90vh;
  max-width: 960px;
  margin: 0 auto;
  padding: 0.6rem;
}
*/
.msg {
  font-weight: bold;
}

.msg.msg-warn {
  color: #b42323;
}

.msg.msg-warn {
  color: #b42323;
}

.msg.msg-warn {
  color: #b42323;
}

.msg.msg-success {
  color: #004a0d;
}

.flex-container {
  display: flex;
}

.flex-container > * {
  flex: 2;
}

footer {
  margin-top: 4rem;
  font-size: 0.9rem;
}

footer p {
  margin: 0 auto;
}

p.fine-print {
  margin: 0.3rem auto;
  font-size: 0.6rem;
}

/* TERMS */

.terms-privacy {
  text-align: left;
}

/* /TERMS */

/* /HOMEPAGE */

.homepage .feature-icon {
  font-size: 2.4rem;
}

.homepage section {
  /*border-bottom: 0.6rem solid #fff9f9;*/
}

.brand-header h1 a {
  color: #000;
  text-decoration: none;
}

.brand-header h1 {
  font-weight: bold;
  font-size: 1.8rem;
}

.brand-header h2 {
  font-size: 0.9rem;
}

.homepage h6 {
  font-size: 0.9rem;
  font-weight: bold;
}

.homepage .section-header {
  font-weight: bold;
}

.homepage .enter-app {
  margin-top: 1.8rem;
}

.homepage .pricing .feature-icon {
  font-size: 1.2rem;
  font-weight: bold;
}

.homepage .pricing .pricing-plan {
  padding: 0.9rem;
}

.homepage .pricing .pricing-plan p {
  margin: 0;
}

/* /HOMEPAGE */

/* TODO LIST */

.app-page .container {
  padding: 0;
  position: relative;
}

.app .brand-header {
  display: flex;
  padding: 1.2rem;
  align-items: center;
}

.app .brand-header h1 {
  flex: 2;
}

.app .brand-header .settings-menu {
  flex: 1;
}

.app .brand-header h1 {
  text-align: left;
  margin: 0;
}

.app .account-options {
  text-align: right;
  position: relative;
}

.app .account-options .settings-menu .toggle {
  cursor: pointer;
  font-size: 2.4rem;
  margin-left: 1.2rem;
}

.todo-list {
  list-style-type: none;
}

.todo-list li {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-left: 0;
  padding: 0.6rem;
}

.todo-list li.header {
  padding: 0;
  border-bottom: 1px solid darkgrey;
}

.todo-list li > * {
  flex: 2;
}

.todo-list .header .actions {
  flex: 1;
}

.todo-list .todo-name {
  font-weight: bold;
  margin-bottom: 0.3rem;
  text-align: left;
  padding-left: 0.6rem;
}

.todo-list .todo-name a {
  text-decoration: none;
}

.todo-list .todo-name i {
  margin-right: 0.6rem;
}

.todo-list .todo-actions {
  font-size: 1.8rem;
  flex: 1;
}

.todo-list .todo-actions a {
  text-decoration: none;
}

.todo-list .todo-item.todo-recently-completed .todo-actions a .check-icon::before {
  content: '\f058';
}

.todo-list .todo-item .todo-actions a:hover .check-icon::before {
  content: '\f058';
}

.todo-item:nth-child(2n+1) {
    background-color: var(--background-tint);
}

.todo-list .todo-item.todo-recently-completed {
  opacity: 0.5;
}

.todo-list .todo-item.todo-recently-completed .todo-name {
  text-decoration: line-through;
}

.todo-list .todo-item .todo-detail {
  display: none;
}

.todo-list .todo-item .todo-detail .additional-actions {
  font-size: 1.8rem;
  flex: 2;
}

.todo-list .todo-item.editing .todo-detail {
  display: flex;
  width: 100%;
  flex: none;
  flex-wrap: wrap;
}

.todo-list .todo-item.editing .todo-detail .info {
  flex: 4;
  text-align: left;
  padding-left: 0.6rem;
}

.todo-list .todo-item.editing .todo-detail .form-container {
  width: 100%;
}

.todo-form .grid-container {
  padding: 0;
}

.todo-form .visibility-toggle .icon-toggle {
  display: inline-block;
  cursor: pointer;
  font-size: 1.2rem;
}

.todo-form .visibility-toggle .icon-toggle i {
  padding: 0.6rem;
}

.todo-form .visibility-toggle .icon-toggle.active i {
  background-color: var(--accent-color);
  color: #fff;
}

.todo-form .repeat-from p {
  font-weight: bold;
  margin-bottom: 0;
}

.todo-form .repeat-from label {
  font-weight: normal;
}

.todo-form .repeat-from input,
.todo-form .repeat-from label {
  display: inline-block;
  margin-right: 0.6rem;
}

.todo-form {
  padding: 1.2rem;
}

.todo-form .button {
  margin-top: 0.6rem;
}

.helptext {
  display: block;
  font-size: 0.75rem;
}

@media only screen and (max-width: 768px) {
    .todo-list .todo-item {
      padding: 0;
    }

    .todo-actions .todo-snooze, .todo-actions .todo-edit {
      display: none;
    }

    .non-app form input,
    .non-app button.button,
    .non-app a.button,
    .non-app .signup input[type="email"] {
      width: 100%;
    }
}